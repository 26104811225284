import axios from "axios";

const global = require("../config/config");

async function getInfoTextos(infoJson) {
  try {
    const API_URL_TEXTOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetInfoTextos/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_TEXTOS);
    const textos = res.data;
    return textos;
  } catch (error) {
    console.log("error", error);
  }
}

async function getInfoPopUp(infoJson) {
  try {
    const API_URL_TEXTOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetPopUp/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_TEXTOS);
    const textos = res.data;
    return textos;
  } catch (error) {
    console.log("error", error);
  }
}

async function getInfoEmpresa(infoJson) {
  try {
    var API_URL_INFO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetContactosClienteAll/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_INFO);
    const info = res.data;
    return info;
  } catch (error) {
    console.log("error", error);
  }
}

async function getBanner(codBanner, infoJson) {
  try {
    const API_URL_BANNERS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetBanner/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
        zona_id: codBanner,
        numero: infoJson.Banner.Top,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_BANNERS);

    if (res.data.length > 0) {
      const banners = res.data;
      return banners;
    }
    return [];
  } catch (error) {
    console.log("error", error);
  }
}

async function getServicoOficina(infoJson) {
  try {
    const API_URL_SERVICOS_OFICINA = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetServicoOficina/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_SERVICOS_OFICINA);
    const servico = res.data;

    return servico;
  } catch (error) {
    console.log("error", error);
  }
}

async function getHorarios(CodPontoVenda, Concelho, infoJson) {
  try {
    const API_URl_HORARIOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetHorario/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
        stand_id: CodPontoVenda,
        country: Concelho,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_HORARIOS);
    const servico = res.data;

    return servico;
  } catch (error) {
    console.log("error", error);
  }
}

async function getIntervaloPreco(infoJson) {
  try {
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetIntervaloPreco/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}
async function getIntervaloMensalidade(infoJson) {
  try {
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/GetIntervaloMensalidade/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}

async function getPrivacidade(infoJson) {
  try {
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/privacidade/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}

async function getCookies(infoJson, nomeEmpresa, email) {
  try {
    const API_URl_INTERVALO = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/empresa/cookies/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
        nomeEmpresa,
        email,
      },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URl_INTERVALO);
    const intervalo = res.data;

    return intervalo;
  } catch (error) {
    console.log("error", error);
  }
}

async function getPlugins(infoJson) {
  try {
    const API_URL_PLUGINS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easysite/GetPlugins/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };
    // dados da api
    const res = await axios.request(API_URL_PLUGINS);
    const data = res.data[0]; // Garantir que acessamos o objeto correto

    const plugins = {
      PluginFacebook: {
        existe: data.FacebookAtivo,
        pixelId: data.FacebookId || "",
        facebookDomainVerification: data.FacebookDomainVerification || "",
      },
      WidgetWhats: {
        existe: data.WhatsappAtivo,
        dataWwa: data.WhatsappId || "",
      },
      OneSignal: {
        existe: data.OneSignalAtivo,
        appId: data.OneSignalId || "",
      },
      WidgeAvaly: {
        existe: data.AvalyAtivo,
        id: data.AvalyId || "",
      },
      Lead2team: {
        existe: data.Lead2TeamAtivo,
        apiKey: data.Lead2TeamId || "",
      },
      GoogleAnalytics: {
        existe: data.GoogleAtivo,
        measurementIds: [data.GoogleAnalytics, data.GoogleTagsManager],
      },
      AutoUncle: {
        existe: data.AutoUncleAtivo,
        config: {
          id: data.AutoUncleId || "",
        },
      },
      Tiktok: {
        existe: data.TiktokAtivo,
        id: data.TiktokId || "",
      },
      OneTrust: {
        existe: data.OneTrustAtivo,
        id: data.OneTrustId || "",
      },
    };

    // infojson

    return {
      PluginFacebook: {
        ...(plugins.PluginFacebook.existe
          ? plugins.PluginFacebook
          : infoJson.Stand.PluginFacebok?.existe
          ? infoJson.Stand.PluginFacebok
          : plugins.PluginFacebook),
      },
      WidgetWhats: {
        ...(plugins.WidgetWhats.existe
          ? plugins.WidgetWhats
          : infoJson.Stand.WidgetWhats?.existe
          ? infoJson.Stand.WidgetWhats
          : plugins.WidgetWhats),
      },
      OneSignal: {
        ...(plugins.OneSignal.existe
          ? plugins.OneSignal
          : infoJson.Stand.OneSignal?.existe
          ? infoJson.Stand.OneSignal
          : plugins.OneSignal),
      },
      WidgeAvaly: {
        ...(plugins.WidgeAvaly.existe
          ? plugins.WidgeAvaly
          : infoJson.Stand.WidgeAvaly?.existe
          ? infoJson.Stand.WidgeAvaly
          : plugins.WidgeAvaly),
      },
      Lead2team: {
        ...(plugins.Lead2team.existe
          ? plugins.Lead2team
          : infoJson.Stand.Lead2team?.existe
          ? infoJson.Stand.Lead2team
          : plugins.Lead2team),
      },
      GoogleAnalytics: {
        ...(plugins.GoogleAnalytics.existe
          ? plugins.GoogleAnalytics
          : infoJson.Stand.GoogleAnalytics?.existe
          ? infoJson.Stand.GoogleAnalytics
          : plugins.GoogleAnalytics),
      },
      AutoUncle: {
        ...(plugins.AutoUncle.existe
          ? plugins.AutoUncle
          : infoJson.Stand.AutoUncle?.existe
          ? infoJson.Stand.AutoUncle
          : plugins.AutoUncle),
      },
      Tiktok: {
        ...(plugins.Tiktok.existe
          ? plugins.Tiktok
          : infoJson.Stand.Tiktok?.existe
          ? infoJson.Stand.Tiktok
          : plugins.Tiktok),
      },
      OneTrust: {
        ...(plugins.OneTrust.existe
          ? plugins.OneTrust
          : infoJson.Stand.OneTrust?.existe
          ? infoJson.Stand.OneTrust
          : plugins.OneTrust),
      },
    };
  } catch (error) {
    console.error(
      "Error fetching plugins:",
      error.response?.data || error.message
    );

    return infoJson.Stand;
  }
}

export {
  getInfoEmpresa,
  getPlugins,
  getBanner,
  getServicoOficina,
  getHorarios,
  getIntervaloPreco,
  getIntervaloMensalidade,
  getInfoTextos,
  getPrivacidade,
  getInfoPopUp,
  getCookies,
};
