import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import TiktokPixel from "tiktok-pixel";
import ReactPixel from "react-facebook-pixel";
import useScriptWidgetsWhats from "./useScriptWidgetsWhats";
import useOneSignal from "./useOneSignal";
import { useLocation } from "react-router-dom";
import { ReactTagManager } from "react-gtm-ts";
import AuthContext from "./AuthContext";

export const initGA = (trackingId) => {
  const éGTM = (item) => item.startsWith("GTM-");
  const arrGA = trackingId
    .filter((item) => !éGTM(item))
    .map((item) => {
      return {
        trackingId: item,
      };
    });
  arrGA.length !== 0 && ReactGA.initialize(arrGA);
  trackingId.filter(éGTM).forEach((item) => {
    ReactTagManager.init({
      code: item,
      debug: false, // debug mode (default false)
      performance: false, // starts GTM only after user interaction (improve initial page load)
    });
  });
};

export default function WidgetProvider({ infoJson, children }) {
  const [initGa, setInitGa] = useState(true);
  const [initFb, setInitFb] = useState(true);
  const [initTt, setInitTt] = useState(true);
  const { state } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (initGa) {
      initGA(state?.plugins?.GoogleAnalytics?.measurementIds);
      setInitGa(false);
    }
    // console.log("state",state)
    // Initialize google analytics
  }, [state?.plugins?.GoogleAnalytics?.measurementIds, initGa ]);

  useScriptWidgetsWhats(state?.plugins);
  useOneSignal(state?.plugins);

  useEffect(() => {
    if (state?.plugins?.PluginFacebook?.existe && initFb) {
      ReactPixel.init(
        state?.plugins?.PluginFacebook?.pixelId,
        {},
        { debug: true, autoConfig: false }
      );
      ReactPixel.pageView();
      ReactPixel.track("PageView");
      setInitFb(false);
    }
  }, [state?.plugins?.PluginFacebook, initFb]);

  useEffect(() => {
    if (state?.plugins?.Tiktok?.existe && initTt) {
      TiktokPixel.init(state?.plugins?.Tiktok?.id, {}, { debug: true });
      TiktokPixel.pageView();
      setInitTt(false);
    }
  }, [state?.plugins?.Tiktok, initTt]);

  useEffect(() => {
    if (state?.plugins?.AutoUncle?.existe && window.auws) {
      const termos = [
        "carros",
        "motas",
        "barcos",
        "pecas",
        "atrelados",
        "motores",
        "viaturas",
        "automoveis",
        "comerciais",
        "classicos",
        "autocaravanas",
        "mobile-home",
        "tractor",
        "low-cost",
        "retomas",
        "novos",
        "vendidos",
      ];

      const termosNaURL = termos.filter((termo) =>
        location.pathname.includes(termo)
      );

      if (termosNaURL.length > 0) {
        window.auws.reload();
      } else {
        window.auws.unload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, infoJson, window.auws]);

  //lead2Team

  useEffect(() => {
    if (state?.plugins?.Lead2team?.existe) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.text = `
        (function (w,d,s,o,f,js,fjs,mpd) { w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
        mpd = d.createElement('div');
        mpd.id = 'widget_'+o; d.body.appendChild(mpd); js = d.createElement(s), fjs = d.getElementById('widget_'+o); js.id = o; js.src = f; 
        js.async = 1; fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'lead2team', 'https://online.lead2team.com/widget/widget.js'));
        lead2team('init', { apiKey: '${state?.plugins?.Lead2team?.apiKey}' });
        lead2team('lead2team', { groups: '', locations: '' });
      `;
      document.body.appendChild(script);
    }

    //One trust
    if (state?.plugins?.OneTrust?.existe) {
      const scriptOneTrust = document.createElement("script");
      scriptOneTrust.src =
        "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
      scriptOneTrust.type = "text/javascript";
      scriptOneTrust.charset = "UTF-8";
      scriptOneTrust.setAttribute(
        "data-domain-script",
        state?.plugins?.OneTrust?.id
      );
      document.head.appendChild(scriptOneTrust);

      const scriptOptanonWrapper = document.createElement("script");
      scriptOptanonWrapper.type = "text/javascript";
      scriptOptanonWrapper.text = `function OptanonWrapper() {}`;
      document.head.appendChild(scriptOptanonWrapper);
    }
  }, [state?.plugins]);

  return <>{children}</>;
}
