import { useEffect } from "react";

const useScriptWidgetsWhats = (plugin) => {
  useEffect(() => {
    if (Object.keys(plugin).length !== 0 && plugin.WidgetWhats.existe) {
      const script = document.createElement("script");
      script.src = "https://s.widgetwhats.com/wwwa.js";
      script.async = true;
      script.setAttribute("data-wwwa", `${plugin.WidgetWhats}`);

      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [plugin]);
};

export default useScriptWidgetsWhats;
